<template>
  <div class="ml-5 mr-5 mb-5 mt-3">
    <b-card-group deck>
      <b-card no-body header="Need Help">
        <b-list-group flush>
          <b-list-group-item
            class="option"
            :to="`/need-help-faq/${orderNumber}`"
            >FAQ</b-list-group-item
          >
          <!-- <b-list-group-item button class="option" @click="onClickChatWithSeller"
            >Chat with Seller</b-list-group-item
          >-->
          <!-- <b-list-group-item button class="option" @click="onClickCallSeller">Call Seller</b-list-group-item> -->
          <b-list-group-item
            class="option"
            :to="{ path: `/need-help-support-ticket/${orderNumber}` }"
            >Support Ticket</b-list-group-item
          >
        </b-list-group>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
export default {
  name: "need-help",
  data() {
    return {
      isLoading: false,
      orderNumber: "",
    };
  },

  methods: {
    onClickSupportTicket() {
      this.$router.push({
        name: "need-help-support-ticket-orderId",
        params: { orderId: this.orderNumber },
      });
    },
    // onClickChatWithSeller() {
    //   this.$router.push(`/need-help-chat-with-seller`);
    // },
    onClickfaq() {
      this.$router.push({
        name: "need-help-faq-orderId",
        params: { orderId: this.orderNumber },
      });
    },
    // onClickCallSeller() {
    //   this.$router.push({
    //     name: "call-seller-orderId",
    //     params: { orderId: this.orderNumber },
    //   });
    // },
  },
  mounted() {
    this.orderNumber = this.$route.params.orderId;
    if (this.$route.path === `/need-help/${this.orderNumber}`) {
      this.onClickfaq();
    }
  },
};
</script>

<style scope>
.router-link-exact-active {
  color: #fd7e14;
}
.router-link-exact-active:hover {
  color: #fd7e14;
}
.card-header,
.option {
  font-weight: 500 !important;
}
</style>
